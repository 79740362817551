<template>
  <div v-once class="offer">
    <div class="offer__ribbon">Букет из 151 розы (40 СМ)</div>
    <CImage
      class="offer__img"
      src="offer_img.webp"
      fallback="offer_img.jpg"
      alt="Букет из 151 розы (40 СМ)"
    />
    <Price />
  </div>
</template>

<script>
import Price from '@/components/ui/Price'
import CImage from '@/components/common/CImage'

export default {
  name: 'Offer',

  components: {
    Price,
    CImage,
  },
}
</script>

<style lang="scss" scoped>
.offer {
  display: flex;
  flex-direction: column;
  grid-area: 1 / 3 / 3 / 4;
  padding: 0 0 rem(12) 0;
  border: rem(2) solid $color-pink2;
  border-radius: rem(2);
  &__ribbon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + #{rem(20)});
    height: rem(36);
    margin: rem(8) rem(-10) 0 rem(-10);
    color: #fff;
    font-weight: 700;
    font-size: rem(15);
    text-transform: uppercase;
    background-color: $color-pink2;
    &:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border-color: transparent $color-pink3 transparent transparent;
      border-style: solid;
      border-width: 0 rem(10) rem(10) 0;
      content: '';
    }
    &:after {
      position: absolute;
      top: 100%;
      right: 0;
      width: 0;
      height: 0;
      border-color: $color-pink3 transparent transparent transparent;
      border-style: solid;
      border-width: rem(10) rem(10) 0 0;
      content: '';
    }
  }
  &__img {
    display: block;
    flex-grow: 1;
    min-height: rem(240);
    margin: rem(12) rem(4) 0 rem(4);
    overflow: hidden;
    border-radius: rem(2);
    /deep/ img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .offer {
    grid-area: 2 / 1 / 4 / 3;
  }
}
@media only screen and (max-width: $breakpoint-tablet) {
  .offer {
    grid-area: 3 / 1 / 5 / 3;
  }
}
@media only screen and (max-width: $breakpoint-large-mobile) {
  .offer {
    grid-area: 3 / 1 / 5 / 5;
  }
}
</style>
