<template>
  <div id="reviews" class="reviews">
    <h3 class="reviews__title">Отзывы клиентов</h3>

    <SwiperAbstraction
      :ref="name"
      class="reviews__slider"
      :name="name"
      :options="options"
      @init="onInit"
    >
      <button
        slot="pagination"
        :class="{ disabled: swiper.progress === 0 }"
        type="button"
        class="reviews__prev"
        @click="swiper.slidePrev()"
      >
        <CIcon class="reviews__chevron" name="chevron-down" />
      </button>
      <button
        slot="pagination"
        :class="{ disabled: swiper.progress === 1 }"
        type="button"
        class="reviews__next"
        @click="swiper.slideNext()"
      >
        <CIcon class="reviews__chevron" name="chevron-down" />
      </button>

      <div v-for="img in 8" slot="slide" :key="img" class="swiper-slide">
        <CImage
          class="reviews__img"
          :src="`review_${img}.webp`"
          :fallback="`review_${img}.jpg`"
          alt="image"
        />
        <p class="reviews__text">
          Огромное спасибо за умение делать сюрпризы! Красивые букеты, хорошие
          цены, быстрая доставка! Лучшая подруга в день рождения на работе, а
          тут букет и поздравления с другого конца России!!! Вы Супер )))
        </p>
        <span class="reviews__author">Юлия</span>
      </div>
    </SwiperAbstraction>
    <nuxt-link to="/" class="reviews__link">
      Все отзывы
      <span class="reviews__count">2797</span>
    </nuxt-link>
  </div>
</template>

<script>
import CIcon from '@/components/common/CIcon'
import CImage from '@/components/common/CImage'
import SwiperAbstraction from '@/components/ui/SwiperAbstraction'

export default {
  name: 'Reviews',

  components: {
    CIcon,
    CImage,
    SwiperAbstraction,
  },

  data() {
    return {
      visible: false,
      swiper: {},
    }
  },

  created() {
    const stamp = Math.random(Date.now()) * Date.now()
    /* TODO: Временное решение по скольку нет реальных данных */
    this.name = Math.round(stamp)

    this.options = {
      slidesPerView: 'auto',
    }
  },

  methods: {
    onInit() {
      this.swiper = this.$refs[this.name].swiper
    },
  },
}
</script>

<style lang="scss" scoped>
.reviews {
  display: flex;
  flex-direction: column;
  grid-area: 1 / 4 / 3 / 5;
  padding-bottom: rem(12);
  border: rem(1) solid $color-green;
  border-radius: rem(2);

  &__title {
    margin: rem(16) 0;
    color: $color-green;
    font-weight: 700;
    font-size: rem(12);
    text-align: center;
    text-transform: uppercase;
  }

  &__slider {
    width: 100%;
    max-width: rem(252);
    margin-bottom: rem(12);
  }

  &__img {
    display: block;
    width: rem(126);
    min-width: rem(126);
    max-width: rem(126);
    height: rem(126);
    min-height: rem(126);
    max-height: rem(126);
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    /deep/ img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__text {
    max-width: rem(200);
    margin: rem(10) auto 0 auto;
    font-size: rem(12);
    line-height: rem(16);
    text-align: center;
  }

  &__author {
    position: relative;
    display: block;
    margin: rem(10) 0 0 0;
    padding-top: rem(10);
    font-size: rem(12);
    font-style: italic;
    text-align: center;
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: rem(2);
      margin: 0 auto;
      background-color: $color-green;
      content: '';
      @include base-width(27);
    }
  }

  &__prev,
  &__next {
    position: absolute;
    top: rem(50);
    z-index: 5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem(27);
    line-height: 1;
    border: rem(1) solid $color-green;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity $default-transition;
    @include base-width(27);
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__prev {
    left: rem(20);
    transform: rotate(90deg);
  }
  &__next {
    right: rem(20);
    transform: rotate(-90deg);
  }

  &__chevron {
    height: rem(10);
    fill: $color-green;
    @include base-width(10);
  }

  &__link {
    position: relative;
    align-self: center;
    margin: auto 0 0 0;
    color: $text-color;
    font-weight: 700;
    font-size: rem(12);
    text-decoration: none;
  }

  &__count {
    margin-left: rem(9);
    padding: 0 rem(9);
    font-size: rem(9);
    background-color: #fff;
    border: rem(1) solid $color-gray3;
    border-radius: rem(9);
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .reviews {
    grid-area: 2 / 3 / 4 / 5;
    &__slider {
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: $breakpoint-tablet) {
  .reviews {
    grid-area: 3 / 3 / 5 / 5;
  }
}
@media only screen and (max-width: $breakpoint-large-mobile) {
  .reviews {
    grid-area: 5 / 1 / 7 / 5;
  }
}
</style>
