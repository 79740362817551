<template>
  <div class="guarantee">
    <Banner />
    <Discount />
    <Offer />
    <Reviews />
  </div>
</template>

<script>
import Banner from '@/components/Guаrantee/Banner'
import Discount from '@/components/Guаrantee/Discount'
import Offer from '@/components/Guаrantee/Offer'
import Reviews from '@/components/Guаrantee/Reviews'

export default {
  name: 'Guarantee',

  components: {
    Banner,
    Discount,
    Offer,
    Reviews,
  },
}
</script>

<style lang="scss" scoped>
.guarantee {
  display: grid;
  grid-row-gap: rem(18);
  grid-column-gap: rem(18);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: rem(18);
}

@media only screen and (max-width: $breakpoint-desktop) {
  .guarantee {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: $breakpoint-tablet) {
  .guarantee {
    grid-template-rows: repeat(4, 1fr);
  }
}
@media only screen and (max-width: $breakpoint-large-mobile) {
  .guarantee {
    grid-template-rows: repeat(6, 1fr);
  }
}
</style>
