<template>
  <div class="banner">
    <CImage
      class="banner__img"
      src="guarantee_banner.webp"
      fallback="guarantee_banner.png"
      alt="Гарантия качества. Если букет не понравится, мы его поменяем"
    />
    <h3 class="banner__title">Гарантия качества</h3>
    <p class="banner__description">Если букет не понравится, мы его поменяем</p>
  </div>
</template>

<script>
import CImage from '@/components/common/CImage'

export default {
  name: 'Banner',

  components: {
    CImage,
  },
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  grid-area: 1 / 1 / 2 / 3;
  min-height: rem(174);
  padding: 0 rem(24);
  overflow: hidden;
  border-radius: rem(2);
  &__img {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    /deep/ img {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    position: relative;
    margin: rem(66) 0 rem(12) 0;
    color: $text-color2;
    font-weight: 500;
    font-size: rem(19);
  }
  &__description {
    position: relative;
    max-width: rem(180);
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: rem(13);
    line-height: rem(18);
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .banner {
    grid-area: 1 / 1 / 2 / 5;
  }
}
</style>
